import React from 'react';
import './SearchSection.css'

class SearchSection extends React.Component{
    render(){
        return(
            <div ref={this.props.scrollRef} className="container container-mb">
                <label id={this.props.norecipes} className="search-recipes">No Recipes found</label>
                <label id={this.props.hide} className="search-recipes">Search Recipes</label>
                <div id={this.props.hide} className="row">
                    {this.props.data}
                </div>
            </div>
        );
    }
}

export default SearchSection;