import React from 'react';
import './Home.css'
import SiteCarousel from '../SiteCarousel/SiteCarousel';
import FilterSection from '../FilterSection/FilterSection';
import NewsTicker from '../NewsTicker/NewsTicker';
import TopBar from '../TopBar/TopBar';
import SearchSection from '../SearchSection/SearchSection';


class Home extends React.Component{
    constructor(props){
        super(props);

        this.executeScroll  = this.executeScroll.bind(this)
        this.search         = this.search.bind(this)
        this.tickerNews     = this.tickerNews.bind(this)
        this.scrollRef      = React.createRef() 

        this.state      = {
            filterstats:"",
            hideSearch:"hide",
            norecipes:"hide",
            searchResult:[],
            news:<div key='0' className="ticker-item">Loading...</div>
        };
    }

    componentDidMount() {
        this.search("", false);
    }

    render(){
        return(
            <div>
                <TopBar
                    handleSearch={this.search}/>
                <NewsTicker
                    handleClick={this.tickerNews} 
                    data={this.state.news}/>
                <SiteCarousel/>
                <FilterSection
                    handleClick={this.tickerNews}/>
                <SearchSection 
                    data={this.state.searchResult}
                    scrollRef={this.scrollRef}
                    norecipes={this.state.norecipes}
                    hide={this.state.hideSearch}/>
            </div>
        );
    }

    executeScroll(){
        this.scrollRef.current.scrollIntoView();
    }

    search(search_entry, scroll){
        const xmlHttp   = new XMLHttpRequest();
        var course  = "";
        var region  = "";
        var time    = "";
        var include = "";
        var exclude = "";
        var entries         = [];
        var doTastykitchen  = true;
        var parameter       = this.state.filterstats.split('&');

        parameter.forEach(element => {
            var filterEntry = element.split('=');

            switch(filterEntry[0]) {
                case "course":
                    course = filterEntry[1].replace('|', ',');
                    doTastykitchen = false;
                    break;
                case "region":
                    region = filterEntry[1].replace('|', ',');
                    doTastykitchen = false;
                    break;
                case "time":
                    var timeArray = filterEntry[1].split(',');
                    if(timeArray[1]==='0')
                        time = '60';
                    else
                        time = timeArray[1]
                    break;
                case "include":
                    include = filterEntry[1].replace('|', ',');
                    break;
                case "exclude":
                    exclude = filterEntry[1].replace('|', ',');
                    break;
                default:
                    break;
            }
        });
        
        // Build URL
        var url = process.env.PUBLIC_URL + '/RecipeSearch.php?search=' + search_entry + '&dtk=' + Number(doTastykitchen) +'&course=' + course + '&region=' + region + '&time=' + time + '&include=' + include + '&exclude=' + exclude;
        //url = 'http://localhost//tastytreasure/RecipeSearch.php?search=' + search_entry + '&dtk=' + Number(doTastykitchen) +'&course=' + course + '&region=' + region + '&time=' + time + '&include=' + include + '&exclude=' + exclude;

        xmlHttp.addEventListener('load', () => {
            var response    = xmlHttp.responseText;
            var recipes     = response.split("||");
            var titel       = "";
            var link        = "";
            var picturelink = "";
            var time        = "";
            var key         = 0;

            if(response.trim().length > 0){
                recipes.forEach(recipe => {
                    var recipeElements = recipe.split("|");
                    titel       = recipeElements[0];
                    link        = recipeElements[1];
                    picturelink = recipeElements[2];
                    time        = recipeElements[3];
                    key         = key + 1;

                    entries.push(<div key={key} className="col-12 col-md-6 col-lg-4 search-result">
                            <a href={link}>
                                <img src={picturelink} className="img-fluid rounded recipe-image" alt="Recipe"/>
                            </a>
                            <div className="row">
                                <div className="col-8 titel">
                                    <label>{titel}</label>
                                </div>
                                <div className="col-4">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-clock" viewBox="0 0 16 16">
                                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                                    </svg>
                                    <label className="recipe-time">{time}</label>
                                </div>
                            </div>
                        </div>);

                    this.setState({searchResult: entries}, () => {
                        if(scroll)
                            this.executeScroll();
                    });
                });
            }

            if(entries.length > 0){
                this.setState({hideSearch: ""});
                this.setState({norecipes: "hide"});
            }
            else{
                this.setState({hideSearch: "hide"});
                this.setState({norecipes: ""});
            }
        });

        this.setState({hideSearch: "hide"}, () => {
            this.setState({norecipes: "hide"}, () => {
                xmlHttp.open("GET", url, true);
                xmlHttp.send();
            });
        });
    }

    tickerNews(parameter) {
        const xmlHttp   = new XMLHttpRequest();
        var url         = "";
        var entries     = [];

        this.setState({filterstats: parameter});

        if(parameter===undefined){
            url = process.env.PUBLIC_URL + '/NewsTicker.php';
            //url = 'http://localhost//tastytreasure/NewsTicker.php';
        }
        else{
            parameter = parameter.replaceAll('|', ',');

            url = process.env.PUBLIC_URL + '/NewsTicker.php?' + parameter;
            //url = 'http://localhost//tastytreasure/NewsTicker.php?' + parameter;
        }

        xmlHttp.addEventListener('load', () => {
            var response = xmlHttp.responseText;
            var recipe  = "";
            var titel   = '';
            var link    = '';
            var key     = 0;

            if(response.trim()){
                var recipes = response.split("|");
                
                for (var i = 0; i < recipes.length; i++) {
                    // Build Paramater
                    recipe  = recipes[i].split(";");
                    titel   = recipe[0];
                    link    = recipe[1];

                    // Build HTML Item
                    entries.push(<div key={key} className="ticker-item"><a href={link}>{titel}</a></div>);
                    key = key + 1;

                    if(i < recipes.length-1){
                        entries.push(<div key={key} className="ticker-item">+++</div>);
                        key = key + 1;
                    }
                }
            }
            else{
                entries.push(<div key='0' className="ticker-item">No recipes found</div>);
            }

            this.setState({news: entries})
        });

        xmlHttp.open("GET", url, true);
        xmlHttp.send();
    };
}

export default Home;