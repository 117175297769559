import React from 'react';
import './TopBar.css';

class TopBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {searchValue: ''};

        this.updateSearchValue = this.updateSearchValue.bind(this);
        this.handleSearchClick = this.handleSearchClick.bind(this);
    }

    updateSearchValue(e){
        this.setState({ searchValue: e.target.value });
    }

    handleSearchClick() {
        this.props.handleSearch(this.state.searchValue, true);
    }

    render(){
        return(
            <nav className="navbar navbar-light">
                <div className="row col-12 m-0">
                    <div className="logo-container col-lg-1 col-md-2 col-sm-2 col-2">
                        <img className="logo" src={process.env.PUBLIC_URL + '/images/TastyTreasureLogo.png'} alt="TastyTreasureLogo"/>
                    </div>

                    <div className="d-none d-xl-block col-lg-2"></div>

                    <div className="search-form col-lg-6 col-md-8 col-sm-9 col-9">
                        <form className="form-inline" onSubmit={e => { e.preventDefault(); this.handleSearchClick()}}>
                            <div className="row">
                                <div className="col-lg-11 col-md-10 col-sm-10 col-10 p-0">
                                    <input className="form-control mr-sm-8" type="text" id="search" value={this.state.searchValue} onChange={evt => this.updateSearchValue(evt)} placeholder="Search recipes" aria-label="Search"/>
                                </div>

                                <div className="col-lg-1 col-md-2 col-sm-2 col-2 p-0">
                                    <button type="button" onClick={() => this.handleSearchClick()} className="btn btn-base test">
                                        <svg xmlns="https://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="d-none d-xl-block col-lg-2"></div>

                    <div className="logo-container col-lg-1 col-md-2 d-none d-md-block">
                        <img className="logo" src={process.env.PUBLIC_URL + '/images/TastyTreasureLogo.png'} alt="TastyTreasureLogo"/>
                    </div>
                </div>
            </nav>
        );
    }
}

export default TopBar;