import React from 'react';
import './NewsTicker.css';


class NewsTicker extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {news:<div className="ticker-item">Loading...</div>};
    }

    componentDidMount() {
        this.props.handleClick();
    }

    render(){
        return(
            <div className="ticker-container">
                <div className="ticker-wrap">
                    <div className="ticker-move">
                        {this.props.data}
                    </div>
                </div>
            </div>
        );
    }
}

export default NewsTicker;