import './App.css';


import Home from './components/Home/Home';
import CookieConsent, { Cookies } from "react-cookie-consent";


function App() {
  return (
    <div className="App">
        <Home/>
        <CookieConsent
          location="bottom"
          disableButtonStyles="true"
          style={{ background: "#2B373B" }}
          cookieName="CookiesAllowed"
          sameSite="none"  
          expires={150}

          buttonText="Sure man!!"
          buttonClasses="btn btn-primary"
          buttonWrapperClasses="cookie-button-wrapper"

          enableDeclineButton="true"
          declineButtonClasses="btn btn-primary"
          declineButtonStyle={{ background:"red"}}
          declineButtonText="Definitely No!"
          onDecline={() => {
            var cookies = Cookies.get();

            for (const [key] of Object.entries(cookies)) {
              Cookies.remove(key, { path: '' });
            }

            window.location.replace('https://www.google.com')
          }}
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
    </div>
  );
}

export default App;