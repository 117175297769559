import React from 'react';
import './FilterSection.css'
import { Multiselect } from 'multiselect-react-dropdown';
import { Cookies } from "react-cookie-consent";

class FilterSection extends React.Component{
    constructor(props){
        super(props);

        this.multiselectRef1     = React.createRef();
        this.multiselectRef2     = React.createRef();
        this.multiselectRef3     = React.createRef();
        this.multiselectRef4     = React.createRef();

        this.clearFilter            = this.clearFilter.bind(this);
        this.applyFilterToTicker    = this.applyFilterToTicker.bind(this);
        this.doFilter               = this.doFilter.bind(this);
        this.doFilterCourse         = this.doFilterCourse.bind(this);
        this.doFilterRegion         = this.doFilterRegion.bind(this);
        this.doFilterTime           = this.doFilterTime.bind(this);
        this.doFilterTime2          = this.doFilterTime2.bind(this);
        this.doFilterInclude        = this.doFilterInclude.bind(this);
        this.doFilterExclude        = this.doFilterExclude.bind(this);

        this.state = {
            selectedCourse:[],
            selectedRegion:[],
            selectedTime:[],
            selectedInclude:[],
            selectedExclude:[],
            filterForTicker: true,
            filterCourse: "",
            filterRegion: "",
            filterTime: "",
            filterInclude: "",
            filterExclude: "",
            recipe_type: [
                {name: 'Breakfast', id: 1},
                {name: 'Lunch', id: 2},
                {name: 'Dinner', id: 3},
                {name: 'Soup', id: 4},
                {name: 'Salad', id: 5},
                {name: 'Dessert', id: 6}
            ],
            region: [
                {name: 'African', id: 1},
                {name: 'American', id: 2},
                {name: 'Asian', id: 3},
                {name: 'Italian', id: 4},
                {name: 'Latin', id: 5},
                {name: 'Mexican', id: 6}
            ],
            ingredients: [
                {name: 'Beef', id: 1},
                {name: 'Cheese', id: 2},
                {name: 'Chicken', id: 3},
                {name: 'Deer', id: 4},
                {name: 'Egg', id: 5},
                {name: 'Lamb', id: 6},
                {name: 'Lettuce', id: 7},
                {name: 'Nuts', id: 8},
                {name: 'Pork', id: 9},
                {name: 'Potatoes', id: 10},
                {name: 'Rice', id: 11},
                {name: 'Salmon', id: 12},
                {name: 'Sugar', id: 13},
                {name: 'Tuna', id: 14},
                {name: 'Turkey', id: 15}
            ]
        };
    }

    componentDidMount() {
        var courses     = [];
        var regiones    = [];
        var timeIdx     = 0;
        var timeValue   = 0;
        var includes    = [];
        var excludes    = [];
        document.getElementById("filterTicker").checked = true;

        var cookies = Cookies.get();

        for (const [key, value] of Object.entries(cookies)) {
            if(key==="course" && value.trim()!==""){
                var coursesObj = value.split("|");

                coursesObj.forEach(courseObj => {
                    var course = courseObj.split(";");
                    courses.push({name: course[0], id: course[1]}); 
                });
            }

            if(key==="region" && value.trim()!==""){
                var regionObj = value.split("|");

                regionObj.forEach(regionObj => {
                    var region = regionObj.split(";");
                    regiones.push({name: region[0], id: region[1]}); 
                });
            }

            if(key==="time" && value.trim()!=="" && value!=="undefined"){
                var timeSelector = document.getElementById("timeSelect").children[value];
                timeSelector.setAttribute('selected', 'true');
                timeIdx     = value;
                timeValue   = timeSelector.value;
            }

            if(key==="include" && value.trim()!==""){
                var includesObj = value.split("|");

                includesObj.forEach(includeObj => {
                    var include = includeObj.split(";");
                    includes.push({name: include[0], id: include[1]}); 
                });
            }

            if(key==="exclude" && value.trim()!==""){
                var excludesObj = value.split("|");

                excludesObj.forEach(excludeObj => {
                    var exclude = excludeObj.split(";");
                    excludes.push({name: exclude[0], id: exclude[1]}); 
                });
            }
        }

        this.setState({
            selectedCourse: courses,
            selectedRegion: regiones,
            selectedTime: timeValue,
            selectedInclude: includes,
            selectedExclude: excludes }, () => {
                this.doFilterCourse(courses, false);
                this.doFilterRegion(regiones, false);
                this.doFilterTime2(timeIdx, timeValue);
                this.doFilterInclude(includes, false);
                this.doFilterExclude(excludes, true);
        });
    }

    render(){
        return(
            <div className="container filter-container">
                <div className="row row-mt">
                    <div className="col-6 col-sm-4 select-item">
                        <label>Recipe type</label> 
                        <Multiselect
                            id="filter1"
                            options={this.state.recipe_type}
                            placeholder="------"
                            onSelect={this.doFilterCourse} 
                            onRemove={this.doFilterCourse}
                            selectedValues={this.state.selectedCourse}
                            ref={this.multiselectRef1}
                            displayValue="name"
                            showArrow="True"
                        />
                    </div>
                    <div className="col-6 col-sm-4 select-item">
                        <label>Region</label>
                        <Multiselect
                            id="filter2"
                            options={this.state.region}
                            placeholder="------"
                            onSelect={this.doFilterRegion} 
                            onRemove={this.doFilterRegion}
                            selectedValues={this.state.selectedRegion}
                            ref={this.multiselectRef2}
                            displayValue="name"
                            showArrow="True"
                        />
                    </div>
                    <div className="col-12 col-sm-4 select-item">
                        <label>Preparation time</label>
                        <select id="timeSelect" className="form-select selectpicker" aria-label="Default select example" onChange={this.doFilterTime.bind(this)} >
                            <option value="-1">------</option>
                            <option value="0,15">less than 15 min</option>
                            <option value="15,30">15 - 30 min</option>
                            <option value="30,45">30 - 45 min</option>
                            <option value="45,60">45 - 60 min</option>
                            <option value="60,0">more than 60 min</option>
                        </select>
                    </div>
                </div>

                <div className="row row-mt">
                    <div className="col-0 col-md-2"></div>

                    <div className="col-6 col-md-4 select-item">
                        <label>Include</label>
                        <Multiselect
                            id="filter3"
                            options={this.state.ingredients}
                            placeholder="------"
                            onSelect={this.doFilterInclude} 
                            onRemove={this.doFilterInclude}
                            selectedValues={this.state.selectedInclude}
                            ref={this.multiselectRef3}
                            displayValue="name"
                            showArrow="True"
                        />
                    </div>
                    <div className="col-6 col-md-4 select-item">
                        <label>Exclude</label> 
                        <Multiselect
                            id="filter4"
                            options={this.state.ingredients} 
                            placeholder="------"
                            onSelect={this.doFilterExclude} 
                            onRemove={this.doFilterExclude}
                            selectedValues={this.state.selectedExclude}
                            ref={this.multiselectRef4}
                            displayValue="name"
                            showArrow="True"
                        />
                    </div>

                    <div className="col-0 col-md-2"></div>
                </div>

                <div className="row row-mt row-mt-small">
                    <div className="col-6 col-md-8 btn-clear-filter">
                        <button type="button" className="btn btn-primary" onClick={this.clearFilter.bind(this)}>Clear Filter</button>
                    </div>

                    <div className="col-6 col-md-4 select-item">
                        <div className="form-check">
                            <input id="filterTicker" className="form-check-input" type="checkbox" onChange={this.applyFilterToTicker.bind(this)}/>
                            <label className="form-check-label">
                                apply selection to ticker
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    clearFilter(event){
        var idxTime = Cookies.get("time");

        if (isNaN(parseInt(idxTime))) {
            idxTime = 0;
        }

        var feObjTime = document.getElementById("timeSelect");
        feObjTime.children[idxTime].removeAttribute('selected', 'true');
        feObjTime.children[0].setAttribute('selected', 'true');
        window.location.reload();

        this.setState({selectedCourse: []});
        this.setState({selectedRegion: []});
        this.setState({selectedTime: []});
        this.setState({selectedInclude: []});
        this.setState({selectedExclude: []});

        this.setState({filterCourse: ""});
        this.setState({filterRegion: ""});
        this.setState({filterTime: ""});
        this.setState({filterInclude: ""});
        this.setState({filterExclude: ""});

        Cookies.set('course', "", { path: '' });
        Cookies.set('region', "", { path: '' });
        Cookies.set('time', "0", { path: '' });
        Cookies.set('include', "", { path: '' });
        Cookies.set('exclude', "", { path: '' });

        this.multiselectRef1.current.resetSelectedValues();
        this.multiselectRef2.current.resetSelectedValues();
        this.multiselectRef3.current.resetSelectedValues();
        this.multiselectRef4.current.resetSelectedValues();
    }

    applyFilterToTicker(event){
        this.setState({filterForTicker: event.target.checked}, () => {
            this.doFilter();
        });
    }

    doFilterCourse(selectedList, secondParam) {
        var filter          = true;
        var course          = "";
        var courseCookie    = "";

        if (typeof secondParam == "boolean") {
            filter = secondParam;
        }
        
        selectedList.forEach(element => {
            course = course + element.name + "|";
            courseCookie = courseCookie + element.name + ";" + element.id + "|";
        });
        course          = course.substr(0, course.length-1);
        courseCookie    = courseCookie.substr(0, courseCookie.length-1);

        Cookies.set('course', courseCookie, { path: '' });

        this.setState({filterCourse: course}, () => {
            if(filter){
                this.doFilter();
            }
        });
    }

    doFilterRegion(selectedList, secondParam) {
        var filter          = true;
        var region          = "";
        var regionCookie    = "";

        if (typeof secondParam == "boolean") {
            filter = secondParam;
        }

        selectedList.forEach(element => {
            region = region + element.name + "|";
            regionCookie = regionCookie + element.name + ";" + element.id + "|";
        });
        region          = region.substr(0, region.length-1);
        regionCookie    = regionCookie.substr(0, regionCookie.length-1);

        Cookies.set('region', regionCookie, { path: '' });

        this.setState({filterRegion: region}, () => {
            if(filter){
                this.doFilter();
            }
        });
    }

    doFilterTime(event){
        var timeObj     = event.target;
        var time        = timeObj.value;
        
        if(time === -1 || time === "-1"){
            time = "";
        }

        Cookies.set('time', timeObj.selectedIndex, { path: '' });

        this.setState({filterTime: time}, () => {
            this.doFilter();
        });
    }

    doFilterTime2(timeIdx, timeValue){        
        if(timeValue === -1 || timeValue === "-1"){
            timeValue = "";
        }

        Cookies.set('time', timeIdx, { path: '' });

        this.setState({filterTime: timeValue});
    }

    doFilterInclude(selectedList, secondParam) {
        var filter          = true;
        var include         = "";
        var includeCookie   = "";

        if (typeof secondParam == "boolean") {
            filter = secondParam;
        }

        selectedList.forEach(element => {
            include = include + element.name + "|";
            includeCookie = includeCookie + element.name + ";" + element.id + "|";
        });
        include         = include.substr(0, include.length-1);
        includeCookie   = includeCookie.substr(0, includeCookie.length-1);

        Cookies.set('include', includeCookie, { path: '' });

        this.setState({filterInclude: include}, () => {
            if(filter){
                this.doFilter();
            }
        });
    }

    doFilterExclude(selectedList, secondParam) {
        var filter          = true;
        var exclude         = "";
        var excludeCookie   = "";

        if (typeof secondParam == "boolean") {
            filter = secondParam;
        }

        selectedList.forEach(element => {
            exclude = exclude + element.name + "|";
            
            excludeCookie = excludeCookie + element.name + ";" + element.id + "|";
        });
        exclude         = exclude.substr(0, exclude.length-1);
        excludeCookie   = excludeCookie.substr(0, excludeCookie.length-1);

        Cookies.set('exclude', excludeCookie, { path: '' });

        this.setState({filterExclude: exclude}, () => {
            if(filter){
                this.doFilter();
            }
        });
    }

    doFilter(){
        var applyFilter = this.state.filterForTicker;
        var parameter = "";

        if(applyFilter){
            try{
                if(this.state.filterCourse.trim().length > 0){
                    parameter = "course=" + this.state.filterCourse + "&";
                }
                if(this.state.filterRegion.trim().length > 0){
                    parameter = parameter + "region=" + this.state.filterRegion + "&";
                }        
                if(this.state.filterTime.length > 0){
                    parameter = parameter + "time=" + this.state.filterTime + "&";
                }
                if(this.state.filterInclude.trim().length > 0){
                    parameter = parameter + "include=" + this.state.filterInclude + "&";
                }
                if(this.state.filterExclude.trim().length > 0){
                    parameter = parameter + "exclude=" + this.state.filterExclude + "&";
                }
            } finally {
                if(parameter.trim().length > 0){
                    parameter = parameter.substr(0, parameter.length-1);
                }
            }
        }

        this.props.handleClick(parameter);
    }
}

export default FilterSection;